export default function Social () {
  return (
    <ul className="links--social links">
        <li>
          <a href="https://twitter.com/EricTheLawrence">
            <span data-icon="ei-sc-twitter" data-size="l"></span>
            <span className="link-content">@EricTheLawrence</span>
          </a>
        </li>
        
        <li>
          <a href="https://github.com/squaretone">
            <span data-icon="ei-sc-github" data-size="l"></span>
            <span className="link-content">@squaretone</span>
          </a>
        </li>

        <li>
          <a href="https://www.linkedin.com/in/ericlawrence/">
            <span data-icon="ei-sc-linkedin" data-size="l"></span>
            <span className="link-content">@EricTheLawrence</span>
          </a>
        </li>
      </ul>
  )
}