import { createContext, useContext, useState } from "react";
import Photo from './img/face_top.png'

const contactInfo = [
  { type: 'name', value: {
    first: 'Eric',
    last: 'Lawrence'
  }},
  { type: 'phone', value: '1.402.871.0339' },
  { type: 'website', value: 'https://www.squaretone.com' },
  { type: 'firstname', value: 'Eric' },
  { type: 'lastname', value: 'Lawrence' },
  { type: 'email', value: 'ericlawrence@gmail.com' },
  { type: 'address', 
    value: {
      street: '16215 Oak Cir', 
      city: 'Omaha', 
      state: 'NE', 
      zipcode: '68130', 
      country: 'USA'
    }
  },
  { type: 'photo', value: Photo },
  { type: 'note', value: 'Eric is awesome. Ask him to hang out some time soon.' },
  { type: 'social', value: 'https://www.twitter.com/EricTheLawrence' },
  { type: 'social', value: 'https://www.linkedin.com/EricTheLawrence' }
]

const defaultState = {
  name: 'Site title here',
  contactInfo
}

const AppContext = createContext(defaultState)

export const AppStateProvider = ({ children }) => {
  const [state, setState] = useState(defaultState)

  const changeTitle = (newTitle) => {
    setState({ ...state, name: newTitle })
  }
  
  const val = { ...state, changeTitle }

  return (
    <AppContext.Provider value={val}>
      {children}
    </AppContext.Provider>
  )
}

export const useAppState = () => {
  return useContext(AppContext)
}
