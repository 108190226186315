export default function Header () {
  return (
    <header>
      <h1><small>Just a little bit</small> About Eric</h1>
      <p className="intro">
        Distinguished pixel farmer crafting and coding in Omaha.
      </p>
    </header>
  )
}
