export default function Contact () {
  return (
    <ul className="links--contact links">
        <li>
          <a href="mailto:ericlawrence@gmail.com?subject=Hey there Eric!">
            <span data-icon="ei-envelope" data-size="s"></span>
            <span className="link-content">EricLawrence@gmail.com</span>
          </a>
        </li>

        <li>
          <a href="tel:4028170339">
            <span data-icon="ei-user" data-size="s"></span>
            <span className="link-content">402.817.0339</span>
          </a>
        </li>
      </ul>
  )
}
