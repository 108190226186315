import Face from './img/face_top.png'

export default function Footer () {
  return (
    <footer>
      <aside>
        <picture>
          <source srcSet={`${Face}?width=400`} />
          <source srcSet={`${Face}?width=400`} type="image/png" />
          <img src={`${Face}?width=200`} alt="Eric" />
        </picture>
      </aside>
    </footer>
  )
}
