import VCard from 'vcard-creator'
import MobileDetect from 'mobile-detect'
import './styles/VCard.scss'

const generateVCard = (contactInfo) => {
  const myVCard = new VCard()
  contactInfo.map((info) => {
    // console.log('vcard item', info)
    
    if (info.type === 'name') {
      myVCard.addName(info.value.last, info.value.first)
    }

    if (info.type === 'email') {
      myVCard.addEmail(info.value)
    }

    if (info.type === 'role') {
      myVCard.addJobtitle(info.value)
    }

    if (info.type === 'website') {
      myVCard.addURL(info.value)
    }

    if (info.type === 'address') {
      const {street, city, state, zipcode, country} = info.value
      myVCard.addAddress(undefined, undefined, street, city, state, zipcode)
    }

    if (info.type === 'phone') {
      myVCard.addPhoneNumber(info.value)
    }

    if (info.type === 'photo') {
      myVCard.addPhoto(info.value)
    }

    if (info.type === 'note') {
      myVCard.addNote(info.value)
    }

    if (info.type === 'social') {
      const startsWith = info.value.split('://')[1].replace('www.', '').split('.')[0]
      myVCard.addURL(info.value, startsWith)
    }
  })

  return myVCard.toString()
}

const CardEl = ( {type, value} ) => {
  const elDisplay = JSON.stringify(value, null, 2)
  return (
    <div>{type}: {elDisplay}</div>
  )
}

// Download VCard based on platform
const cardDownloader = (vcfData, fileName = 'contact.vcf') => {
  const isMobile = new MobileDetect(window.navigator.userAgent).mobile()
  if (isMobile) {
    downloadVCardMobile(vcfData, fileName)
  } else {
    downloadVCardDesktop(vcfData, fileName)
  }
}

// Mobile downloader
const downloadVCardMobile = (cardText, fileName = 'contact.vcf') => {
  const cardContent = encodeURI(cardText)
  window.open("data:text/x-vcard;urlencoded," + cardContent);
}

// Desktop downloader
const downloadVCardDesktop = (cardText, fileName = 'contact.vcf') => {
  const content = new Blob([cardText])
  const url = window.URL.createObjectURL(content)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
}

export default function VCard ({ contactInfo }) {
  const downloadVCardHandler = (evt) => {
    evt.preventDefault()
    const cardDataTxt = generateVCard(contactInfo)
    cardDownloader(cardDataTxt, 'eric_lawrence.vcf')
  }

  const displayItems = contactInfo.filter((info) => {
    const hiddenElements = ['firstname', 'lastname']
    const shouldDisplay = hiddenElements.indexOf(info.type) < 0
    return shouldDisplay
  })

  const displayVCardItems = (displayItems) => {
    {displayItems.map((cardItem, index) => {
      return <CardEl {...cardItem} key={`item_${cardItem.type}_${index}`} />
    })}
  }

  return (
    <div className="VCard">
      <a 
        href='/' 
        onClick={downloadVCardHandler}
        className="contact-download"
        >
          Download Contact
        </a>
    </div>
  )
}
