import Header from './Header'
import Social from './Social'
import Contact from './Contact'
import Footer from './Footer'
import VCard from './VCard'
import { useAppState } from './state'

export default function App() {
  const { contactInfo } = useAppState()

  return (
    <>
      <Header />
      <main>
        <Social />
        <Contact />
        <VCard contactInfo={contactInfo} />
      </main>
      <Footer />
    </>
  )
}